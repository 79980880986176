<template>
  <div v-html="p.description" />
</template>
<script>
import { authComputed } from '@/store/helpers.js'
import store from '@/store/index'
import jQuery from 'jquery'

function getPage (routeTo, next) {
  var pageId = null
  switch (routeTo.params.page_type) {
    case 'about':
      pageId = 1
      break
    case 'howto':
      pageId = 2
      break
    case 'company':
      pageId = 3
      break
    case 'privacy':
      pageId = 4
      break
    case 'terms':
      pageId = 5
      break
    default:
  }

  if (pageId === null) {
    next({ name: '404', params: { resource: 'ページ' } })
  } else {
    store
      .dispatch('page/fetchPage', { id: pageId })
      .then(page => {
        if (!page.node) {
          next({ name: '404', params: { resource: 'ページ' } })
        } else {
          routeTo.params.p = page.node
          next()
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          next({ name: '404', params: { resource: 'ページ' } })
        } else {
          next({ name: 'network-issue' })
        }
      })
  }
}

export default {
  props: {
    p: {
      type: Object,
      required: true
    }
  },
  beforeRouteUpdate (routeTo, routeFrom, next) {
    getPage(routeTo, next)
  },
  computed: {
    ...authComputed
  },
  mounted () {
    global.jquery = jQuery
    global.$ = jQuery
    window.$ = window.jQuery = require('jquery')
    if (window.$('.nav_category_box').length) {
      var navHeight = window.$('.nav_category_box').outerHeight()
      window.$(window).on('load resize scroll', function () {
        var pos = window.$('.nav_category_box').offset().top + navHeight
        var scroll = window.$(window).scrollTop()
        if (scroll > pos) {
          window.$('.nav_category_bar').addClass('js__on')
        } else {
          window.$('.nav_category_bar').removeClass('js__on')
        }
      })
      window
        .$('.nav_category_bar ul li a, .nav_category_box ul li a')
        .on('click', function () {
          var href = window.$(this).attr('href')
          var ost = window.$(href).get(0).offsetTop
          if (!window.$('.nav_category_bar').hasClass('js__on')) {
            ost -= window
              .$('.nav_category_bar ul li')
              .eq(0)
              .outerHeight()
          }
          window.$('html, body').animate({ scrollTop: ost }, 500, 'swing')
          return false
        })
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
